.organization {
	margin-bottom: 3rem;

	&-heading {
		margin-bottom: 0.5rem;
	}

	&-list {
		margin-bottom: 2rem;
	}
}
