.introduction {
	@include set-as-content-container;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	margin-top: 5rem;

	@media ($breakpoint){
		margin-top: 11rem;
	}

	&-invitation {
		margin-bottom: 2rem;
	}

	&-heading {
		margin-bottom: 3rem;
		&-black {
			color: initial;
		}
	}

	&-date {
		font-size: 1.3em;
		color: $color-minor;
		margin-bottom: 1rem;
	}

	&-place {
		font-size: 1.3em;
		color: initial;
		margin-bottom: 2rem;
	}

	&-promoters {
		margin: 0 auto 3rem auto;
		max-width: 25rem;
	}
}
