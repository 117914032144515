@mixin set-as-container ($maxWidth) {
	margin-left: auto;
	margin-right: auto;
	max-width: $maxWidth;
}

@mixin set-as-content-container () {
	@include set-as-container(30rem);
}
@mixin set-as-page-container () {
	@include set-as-container(70rem);
}
