$breakpoint: 'min-width: 40rem';

.header {
	&-background {
		background-color: $color-main;
		height: 11rem;

		@media ($breakpoint) {
			height: 19rem;
		}

		&-image {
			position: relative;
			bottom: -4.75rem;
			width: 15rem;

			@media ($breakpoint) {
				width: 27rem;
				bottom: -7rem;
			}
		}
	}
}
