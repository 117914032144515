.footer {
	background-color: $color-main;
	padding: 2rem 0;
	color: #fff;

	&-heading {
		font-size: 1.1rem;
	}

	&-contact {
		color: $color-default;
		text-decoration: underline;
		&:active,
		&:focus,
		&:hover {
			color: $color-tinge;
		}
	}
}
