$color-main: #008DD2;
$color-minor: #E5097F;
$color-tinge: #E7E8EC;
$color-boxShadow: #7e7e7e;
$color-text: #000000;
$color-default: #ffffff;



:root,
html,
body {
	font-family: myriad-pro, Arial, sans-serif;
	font-size: 18px;
	line-height: 1.3rem;
	text-align: center;
}

a {
	color: $color-text;
	&:active,
	&:focus,
	&:hover {
		color:$color-main;
	}
}

h2,
h3,
h4,
h5 {
	color: $color-main;
	font-weight: normal;
	margin-bottom: 1.5rem;
	line-height: 2rem;
}

h2 {
	font-size: 1.4em;
}

h3 {
	font-size: 1.6em;
	text-transform: uppercase;
}

h4,
h5 {
	font-size: 1.2rem;
}
li {
	list-style: none;
}
