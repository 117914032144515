$breakpoint-maxWidth: 'max-width: 45rem';
$breakpoint-minWidth: 'min-width: 45.001rem';

.navigation {
	position: fixed;
	background-color: $color-main;
	left: 0;
	top:0;
	width:100vw;
	border-bottom: 2px solid $color-default;
	z-index: 1;

	@media ($breakpoint-minWidth) {
		background-color: $color-default;
		border-bottom-color: $color-boxShadow;
	}

	&-wrapper {
		@media ($breakpoint-maxWidth) {
			display: flex;
			justify-content: space-between;
			padding-bottom: 1rem;
			padding-top: 1rem;
		}

		display: inline-flex;
		width: 45rem;
		margin-right: 24rem;
		padding: 1rem;
	}

	&-wrapperImage {
		padding-left: 2rem;
	}

	&-mobileImage {
		@media ($breakpoint-minWidth) {
			display: none;
		}
	}

	&-image {
		@media ($breakpoint-maxWidth) {
			display: none;
		}

		@media ($breakpoint-minWidth) {
			width: 8.3rem;
			padding-right: 4rem;
			display: inline;
		}
	}

	&-hamburger {
		cursor: pointer;
		position: fixed;
		right: 0;

		@media ($breakpoint-minWidth) {
			display: none;
		}

		&-image {
			height: 2rem;
			padding-right: 2rem;
		}

	}

	&-wrapperLink {
		display: none;
		
		&-isActive {
			@media ($breakpoint-maxWidth) {
				display: block;
				position: absolute;
				top: 4.5rem;
				right: 0;
				text-align: left;
			}
		}

		@media ($breakpoint-minWidth) {
			text-align: left;
			padding-left: 1rem;
			display: inline-flex;
		}
	}

	&-item {

		&-link {
			text-transform: uppercase;
			display: block;

			@media ($breakpoint-maxWidth) {
				background-color: $color-tinge;
				padding: 1rem 5rem 1rem 1rem;
				font-weight: bold;
				border-bottom: 1px solid $color-default;
			}

			@media ($breakpoint-minWidth) {
				display: inline-block;
				padding-left: 1.5rem;
				padding-top: 2rem;
				font-size: 1.3em;
				padding-right: 4rem;
			}
		}
	}
}
