.program {
	background-color: $color-tinge;
	margin-bottom: 3rem;
	padding: 2rem 0.5rem;
	position: relative;

	&-anchor {
		position: absolute;
		top:-3.9rem;

		@media ($breakpoint){
			top:-6.5rem;
		}
	}

	&-blok {
		margin: 0 auto 2rem auto;
		max-width: 25rem;

		&-headingUpper {
			text-transform: uppercase;
		}

		&-list {
			margin-bottom: 1.5rem;

			&-item {
				margin-bottom: 1rem;

				&-italic {
					font-style: italic;
				}
			}
		}
	}

	&-break {
		font-size: 1.2em;
		margin: 0 auto 3rem auto;
		border-top-width: 1px;
		border-bottom-width: 1px;
		max-width: 10rem;
		padding: 2rem 0;
	}

	&-workshop {
		max-width: 25rem;
		margin: 0 auto;
		border-top-width: 1px;
		padding-top: 2rem;
		
		&-headingUpper {
			text-transform: uppercase;
		}

		&-partWorkshop {
			margin-bottom: 2rem;
			&-list {
				&-item {
					&-italic {
						font-style: italic;
					}
				}
			}
		}
	}

	&-notice {
		font-weight: bold;
		margin-bottom: 2rem;
	}
}
