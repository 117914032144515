.registrationButton {
	background-color: $color-minor;
	display: block;
	padding: 1rem 2rem;
	max-width: 14rem;
	margin: 0 auto 3rem auto;
	box-shadow: 5px 3px 5px $color-boxShadow;
	font-weight: bold;
	color: $color-default;

	&:hover,
	&:active,
	&:focus {
		color: $color-default;
	}
	&:hover {
		background-color: darken($color-minor, 5);
		text-decoration: none;
	}
}
